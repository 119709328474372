<template>
  <div class="filter-input-wrapper">
    <div class="filter-input" v-click-outside="hideSelector">
      <div
        v-for="item in filteredOptions"
        :key="item.key"
        class="filter-item"
        @click="$emit('filter', item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="reset-button">
      <a-button type="primary" @click="$emit('filter', '')">сброс</a-button>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "UkTableFilterSelector",
  props: ["filteredOptions"],
  methods: {
    hideSelector(event) {
      if (event.target.className === "filter-off") return;
      if (event.target.className === "filter") return;
      this.$emit("filter", undefined);
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
.filter-input-wrapper {
  position: absolute;
  z-index: 100;
  width: 200px;
  margin-top: 8px;
}

.filter-item {
  background-color: white;
  padding: 8px;
  border: 1px solid rgb(12, 144, 240);
  margin-bottom: -1px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.filter-input :first-child {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.filter-input :last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.reset-button {
  margin-top: 8px;
  text-align: right;
}
</style>
