<template>
  <a-spin :spinning="loading">
    <div class="white-popup popup_add_user" id="add_user">
      <div class="top">
        {{
          cardData.key ? "Редактировать пользователя" : "Добавить пользователя"
        }}
      </div>
      <form class="popup_form">
        <div class="popup_form_block">
          <label>Имя<span>*</span></label>
          <input type="text" v-model="name" />
        </div>
        <div class="popup_form_block">
          <label>Фамилия<span>*</span></label>
          <input type="text" v-model="surname" />
        </div>
        <div class="popup_form_block">
          <label>Доступ к локациям<span>*</span></label>
          <SelectMultiple
            :listItems="restArray"
            :values="restaurant"
            @change="(items) => (restaurant = items)"
          />
        </div>
        <div class="popup_form_block">
          <label>E-mail<span>*</span></label>
          <input type="email" v-model="email" />
        </div>
        <FormInputPassword
          v-model="password"
          label="Пароль"
          :required="!cardData.key"
        />
        <FormInputPassword
          v-model="c_password"
          label="Подтвердите пароль"
          :required="!cardData.key"
        />
        <input
          @click.prevent="saveCard"
          type="submit"
          value="Сохранить"
          :disabled="!isTouched()"
        />
      </form>
    </div>
  </a-spin>
</template>

<script>
import { FormHelper } from "@/helpers/formHelper";
import SelectMultiple from "@/components/common/SelectMultiple";
import FormInputPassword from "@/components/common/FormInputPassword";
import { getPoints, putUser, postManager } from "@/helpers/dataHelpers";
import { bus } from "@/bus";

const formHelper = new FormHelper([
  "name",
  "surname",
  "restaurant",
  "email",
  "password",
  "c_password",
]);

export default {
  name: "EditEmployees",
  props: ["cardData"],
  data() {
    formHelper.setNewData(this, this.cardData);
    return formHelper.addFormFields({
      loading: true,
      restArray: [],
    });
  },
  components: { SelectMultiple, FormInputPassword },
  methods: {
    saveCard: function () {
      const mappedData = {
        name: this.name,
        surname: this.surname,
        email: this.email,
        password: this.password,
        c_password: this.c_password,
        pointId: this.restaurant.map((item) => item.key),
      };

      if (this.cardData.key) {
        putUser(this.cardData.key, mappedData, (response) =>
          bus.$emit("Employees update after save", response.data.data)
        );
      } else {
        postManager(mappedData, (response) => {
          bus.$emit(
            "Employees push and go to the last page",
            response.data.data
          );
        });
      }

      formHelper.emitSave("saveCard Employees", "key");
    },
    isTouched() {
      return formHelper.isTouched();
    },
  },
  watch: {
    cardData: {
      handler: function (val) {
        formHelper.updateFields(val);
      },
      deep: true,
    },
  },
  mounted() {
    getPoints((response) => {
      this.restArray = response.data.data.map((item) => ({
        key: item.pointId,
        name: item.name,
      }));
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.popup_form_block {
  position: relative;
}

.eye-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 42px;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
