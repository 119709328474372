<template>
  <div class="popup_form_block">
    <label>{{ label }}<span v-if="required">*</span></label>
    <input
      :type="visible ? 'text' : 'password'"
      v-model="content"
      @input="handleInput"
    />
    <div class="eye-button" @click="toggleVisible()">
      <svg
        v-if="visible"
        width="20"
        height="16"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 26.32 17.78"
      >
        <path
          d="M13.16,17.78C6,17.78.42,9.52.19,9.17L0,8.89l.19-.28C.42,8.26,6,0,13.16,0s12.73,8.26,13,8.61l.19.28-.19.28C25.89,9.52,20.28,17.78,13.16,17.78ZM1.21,8.89c1,1.43,6.06,7.89,12,7.89s10.9-6.46,11.94-7.89C24.06,7.46,19,1,13.16,1S2.26,7.46,1.21,8.89Z"
          fill="#C4C4C4"
        />
        <path
          d="M13.16,13.8a4.92,4.92,0,1,1,4.91-4.91A4.92,4.92,0,0,1,13.16,13.8Zm0-8.83a3.92,3.92,0,1,0,3.91,3.92A3.93,3.93,0,0,0,13.16,5Z"
          fill="#C4C4C4"
        />
      </svg>
      <svg
        v-else
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5125 11.8563C18.6151 9.9799 19.7771 7.85379 19.7771 7.85379C19.7771 7.85379 16.0688 1.05518 9.8882 1.05518C8.70103 1.05926 7.5273 1.30663 6.43945 1.78201L7.39126 2.73505C8.19156 2.44352 9.03646 2.29336 9.8882 2.29129C12.5088 2.29129 14.6831 3.73506 16.2764 5.32841C17.0433 6.09936 17.7307 6.9454 18.3284 7.85379C18.2567 7.96133 18.1776 8.08 18.0873 8.20979C17.6732 8.80312 17.0614 9.59423 16.2764 10.3792C16.0725 10.5831 15.8599 10.7846 15.6374 10.9799L16.5125 11.8563Z"
          fill="#C4C4C4"
        />
        <path
          d="M13.9633 9.30781C14.2391 8.53628 14.2902 7.70228 14.1106 6.90286C13.931 6.10343 13.528 5.37145 12.9487 4.79207C12.3693 4.2127 11.6373 3.80976 10.8379 3.63014C10.0385 3.45052 9.20446 3.50161 8.43293 3.77745L9.45025 4.79477C9.9253 4.72677 10.4097 4.77035 10.8649 4.92204C11.3202 5.07374 11.7339 5.32938 12.0733 5.66871C12.4126 6.00805 12.6682 6.42175 12.8199 6.87703C12.9716 7.33232 13.0152 7.81668 12.9472 8.29173L13.9633 9.30781ZM10.3254 10.9135L11.3415 11.9296C10.57 12.2054 9.73598 12.2565 8.93655 12.0769C8.13712 11.8973 7.40514 11.4944 6.82576 10.915C6.24639 10.3356 5.84345 9.60362 5.66383 8.80419C5.48421 8.00477 5.5353 7.17077 5.81114 6.39924L6.82846 7.41656C6.76046 7.89161 6.80404 8.37597 6.95573 8.83125C7.10743 9.28654 7.36307 9.70024 7.7024 10.0396C8.04174 10.3789 8.45544 10.6346 8.91073 10.7862C9.36601 10.9379 9.85037 10.9815 10.3254 10.9135Z"
          fill="#C4C4C4"
        />
        <path
          d="M4.14097 4.72722C3.91847 4.92499 3.70463 5.12524 3.50067 5.3292C2.73384 6.10015 2.04641 6.94619 1.44872 7.85458L1.68976 8.21058C2.10386 8.80391 2.71574 9.59502 3.50067 10.38C5.09401 11.9733 7.26957 13.4171 9.88889 13.4171C10.7739 13.4171 11.6071 13.2527 12.3858 12.9721L13.3376 13.9264C12.2498 14.4017 11.0761 14.6491 9.88889 14.6532C3.70833 14.6532 0 7.85458 0 7.85458C0 7.85458 1.16071 5.72723 3.26457 3.85205L4.13974 4.72845L4.14097 4.72722Z"
          fill="#C4C4C4"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.8685 15.7085L2.03516 0.875167L2.91032 0L17.7437 14.8333L16.8685 15.7085Z"
          fill="#C4C4C4"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormInputPassword",
  props: ["label", "value", "required"],
  data() {
    return {
      content: this.value,
      visible: false,
    };
  },
  methods: {
    handleInput() {
      this.$emit("input", this.content);
    },
    toggleVisible() {
      this.visible = !this.visible;
    },
  },
};
</script>

<style scoped>
.popup_form_block {
  position: relative;
}

.eye-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 42px;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
