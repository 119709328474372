<template>
  <div class="column" :class="'item_' + classSuffix">
    <div class="letter" :class="getNextCalssColor()">
      {{ data.split(" ").pop().substring(0, 1) }}
    </div>
    {{ data }}
  </div>
</template>

<script>
import { random } from "lodash";

export default {
  name: "UkTableRowItemFio",
  props: {
    data: {
      type: String,
      default: "",
    },
    classSuffix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      classColors: ["orange", "blue", "green"],
    };
  },
  methods: {
    getNextCalssColor() {
      return this.classColors[random(0, this.classColors.length - 1)];
    },
  },
};
</script>
