<template>
  <div>
    <a-spin :spinning="loading">
      <div class="top">
        <div class="caption">
          {{ $route.name }}
        </div>
        <a
          class="add_user open-popup-link"
          href="#add_user"
          @click.prevent="addUser"
        >
          Добавить пользователя
        </a>
      </div>
      <div class="middle">
        <div class="full_users">
          <UkTable :columns="columns" :data="computedData" :itemsPerPage="10">
            <div class="uk-table-slot">
              <div>Сотрудников пока нет</div>
              <a @click.prevent="addUser">Добавить сотрудника</a>
            </div>
          </UkTable>
        </div>
      </div>
      <Drawer :showDrawer="showDrawer" :close="closeDrawer" :maxWidth="600">
        <EditEmployees :cardData="cardData" />
      </Drawer>
    </a-spin>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import { cloneDeep } from "lodash";
import { notification } from "ant-design-vue";

import { bus } from "@/bus";

import UkTable from "@/components/common/UkTable";
import UkTableRowItemFio from "@/components/common/UkTableRowItemFio";
import UkTableRowItemRestaurantList from "@/components/common/UkTableRowItemRestaurantList";
import UkTableRowItemDate from "@/components/common/UkTableRowItemDate";
import UkTableRowItemEdit from "@/components/common/UkTableRowItemEdit";

import EditEmployees from "@/components/common/EditEmployees";
import Drawer from "@/components/common/Drawer";

import { getUsers, getPoints } from "@/helpers/dataHelpers";

const columns = [
  {
    name: "Имя / Фамилия",
    classSuffix: "name",
    id: "nameSurname",
    component: UkTableRowItemFio,
  },
  {
    name: "Локации сотрудника",
    classSuffix: "rest",
    id: "restaurant",
    component: UkTableRowItemRestaurantList,
  },
  {
    name: "Дата создания",
    classSuffix: "date",
    id: "date",
    component: UkTableRowItemDate,
    sorter: (a, b) => a - b,
  },
  {
    name: "",
    classSuffix: "edit",
    id: "edit",
    component: UkTableRowItemEdit,
  },
];

function mappingB2F(ob) {
  return {
    key: ob.id,
    nameSurname: ob.surname ? ob.surname + " " + ob.name : ob.name,
    name: ob.name,
    surname: ob.surname,
    email: ob.email,
    restaurant: ob.points.map((item) => ({
      name: item.name,
      key: item.pointId,
    })),
    date: moment.utc(ob.created_at, "YYYY-MM-DD[T]HH:mm:ss.SSSSSS[Z]").local(),
  };
}

export default {
  name: "Employees",
  data() {
    return {
      data: [],
      columns,
      showDrawer: false,
      cardData: {},
      loading: true,
      hasReastaurants: false,
    };
  },
  components: {
    UkTable,
    EditEmployees,
    Drawer,
  },
  methods: {
    addUser: function () {
      if (this.hasReastaurants) {
        this.cardData = {};
        this.showDrawer = true;
      } else {
        notification.open({
          message:
            "Что-бы добавить сотрудника необходимо создать хотя-бы одну локацию",
        });
      }
    },
    closeDrawer: function () {
      this.showDrawer = false;
      this.cardData = {};
    },
  },
  computed: {
    computedData: function () {
      function addEmitters(data) {
        data.forEach((item) => {
          item.edit = () => bus.$emit("showDrawer Employees", item);
        });
      }
      addEmitters(this.data);

      return cloneDeep(this.data);
    },
  },
  created() {
    bus.$on("showDrawer Employees", (cardData) => {
      this.showDrawer = true;
      this.cardData = cardData;
    });

    bus.$on("Employees update after save", (newData) => {
      const mappedData = mappingB2F(newData);
      const item = this.data.find((item) => item.key === mappedData.key);
      Object.assign(item, mappedData);
      bus.$emit("UkTable stay at page");
      this.closeDrawer();
    });

    bus.$on("Employees push and go to the last page", (newData) => {
      this.data.push(mappingB2F(newData));
      bus.$emit("UkTable go to last page");
      this.closeDrawer();
    });
  },
  mounted: function () {
    getUsers((response) => {
      this.data = response.data.data.map(mappingB2F);

      getPoints((response) => {
        this.hasReastaurants = response.data?.data?.length > 0;
        this.loading = false;
      });
    });
  },
};
</script>

<style scoped>
.customTitle {
  color: #1890f0;
}

.restaurant-item {
  border: 1px solid;
  padding: 3px;
}

.component-header {
  display: flex;
  align-items: left;
}

.uk-table-slot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
}

.uk-table-slot a {
  text-decoration: underline;
}
</style>
