<template>
  <div class="column" :class="'item_' + classSuffix">
    <div
      v-for="item in data"
      :key="item.key"
      class="rest"
      :class="getNextCalssColor()"
    >
      <span class="name_rest">{{ item.name }}</span>
      <span class="tooltip">{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
import { random } from "lodash";

export default {
  name: "UkTableRowItemRestaurantList",
  data() {
    return {
      classColors: ["pink", "blue", "green", "red", "purple", "violet"],
      lastColor: "",
    };
  },
  props: ["data", "classSuffix"],
  methods: {
    getNextCalssColor() {
      const colorIndex = random(0, this.classColors.length - 1);
      return this.classColors[colorIndex];
    },
  },
};
</script>
