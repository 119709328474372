<template>
  <div class="column" :class="'item_' + classSuffix">
    <a href="" @click.prevent="data">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5909 1.58492C11.7763 1.39948 11.9964 1.25238 12.2387 1.15201C12.481 1.05165 12.7407 1 13.003 1C13.2652 1 13.5249 1.05165 13.7672 1.15201C14.0095 1.25238 14.2296 1.39948 14.4151 1.58492C14.6005 1.77036 14.7476 1.99051 14.848 2.2328C14.9483 2.47509 15 2.73478 15 2.99703C15 3.25928 14.9483 3.51897 14.848 3.76126C14.7476 4.00355 14.6005 4.2237 14.4151 4.40914L4.88331 13.9409L1 15L2.05909 11.1167L11.5909 1.58492Z"
          stroke="#808080"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path></svg
    ></a>
  </div>
</template>

<script>
export default {
  name: "UkTableRowItemEdit",
  props: ["data", "record", "classSuffix"],
};
</script>
