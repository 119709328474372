<template>
  <div class="item">
    <component
      v-for="item in columns"
      :key="item.id"
      :is="item.component"
      :data="data[item.id]"
      :record="data"
      :classSuffix="item.classSuffix"
      :style="{ 'min-height': '70px' }"
    />
  </div>
</template>

<script>
export default {
  name: "UkTableRow",
  props: ["data", "columns"],
};
</script>

<style lang="scss" scoped></style>
