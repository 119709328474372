import { cloneDeep, isEqual } from "lodash";
import { bus } from "@/bus";

export class FormHelper {
  formFields = undefined; // список полей формы
  formThis = undefined; // ссылка на this компонента
  beforeChangeData = undefined; // копия данных для фиксации изменений

  constructor(formFields) {
    this.formFields = formFields;
  }

  setNewData(formThis, data) {
    this.formThis = formThis;
    this.beforeChangeData = cloneDeep(data);
  }

  addFormFields(ob) {
    this.formFields.forEach(
      (key) => (ob[key] = cloneDeep(this.beforeChangeData[key]))
    );
    return ob;
  }

  isTouched() {
    const ret = this.formFields.some(
      (key) => !isEqual(this.formThis[key], this.beforeChangeData[key])
    );
    return ret;
  }

  updateFields(val) {
    this.beforeChangeData = cloneDeep(val);
    this.formFields.forEach((item) => {
      this.formThis[item] = cloneDeep(val[item]);
    });
  }

  emitSave(emitMsg, keyField) {
    const retOb = {};
    this.formFields.forEach(
      (key) => (retOb[key] = cloneDeep(this.formThis[key]))
    );
    retOb[keyField] = this.beforeChangeData[keyField];
    bus.$emit(emitMsg, retOb);
  }
}
