<template>
  <div class="bottom" v-if="length > 0">
    <div class="count" @click="showSelector" v-click-outside="hideSelector">
      {{ itemsPerPage }}
      <div v-if="selectorIsVisible" class="selector">
        <div
          class="selector-item"
          v-for="item in items"
          :key="item"
          @click="changeItemsPerPage(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="pagination">
      <ul>
        <li v-if="currentPage !== 1">
          <a
            @click.prevent="$emit('changeCurrentPage', currentPage - 1)"
            href=""
            ><svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.47021 11.4461L1.47021 5.94614L5.47021 0.446136"
                stroke="black"
              ></path></svg
          ></a>
        </li>
        <li v-for="item in numberOfButtons" :key="item">
          <a
            :class="{ active: item === currentPage }"
            @click.prevent="$emit('changeCurrentPage', item)"
            href=""
            >{{ item }}</a
          >
        </li>
        <li v-if="currentPage !== numberOfButtons">
          <a
            @click.prevent="$emit('changeCurrentPage', currentPage + 1)"
            href=""
            ><svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.470825 0.445938L4.47083 5.94594L0.470827 11.4459"
                stroke="black"
              ></path></svg
          ></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "UkTablePagination",
  props: ["itemsPerPage", "currentPage", "length"],
  data() {
    return {
      selectorIsVisible: false,
      items: [5, 10, 15, 25, 50],
    };
  },
  methods: {
    showSelector() {
      this.selectorIsVisible = true;
    },
    hideSelector() {
      this.selectorIsVisible = false;
    },
    changeItemsPerPage(n) {
      this.hideSelector();
      this.$emit("changeItemsPerPage", n);
    },
  },
  components: {},
  directives: {
    ClickOutside,
  },
  computed: {
    numberOfButtons: function () {
      let retN = Math.floor((this.length - 1) / this.itemsPerPage) + 1;
      return retN;
    },
  },
};
</script>

<style scoped>
.selector {
  position: absolute;
  background-color: white;
  top: 25%;
}

.selector-item {
  padding: 4px 8px;
  border: 1px solid var(--use-color-primary-810);
  margin-bottom: -1px;
  text-align: center;
}

.selector :first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.selector :last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>
>
