<template>
  <div class="column" :class="'item_' + classSuffix">
    <div class="add_date">{{ dateFormatted }}</div>
  </div>
</template>

<script>
export default {
  name: "UkTableRowItemDate",
  props: ["data", "classSuffix"],
  computed: {
    dateFormatted: function () {
      return this.data?.format("DD.MM.YY H:mm");
    },
  },
};
</script>
