<template>
  <div class="custom-select">
    <div
      class="select-selected"
      :class="{ 'select-arrow-active': isOpened }"
      @click.stop="toggle"
    >
      <span v-for="(item, index) in selectedItems" :key="item.key"
        >{{ item.name }}
        <span v-if="index != selectedItems.length - 1">, </span>
      </span>
    </div>
    <div
      class="select-items"
      :class="{ 'select-hide': !isOpened }"
      v-click-outside="close"
    >
      <div v-for="item in listItems" :key="item.key" @click="addCurrent(item)">
        <span>{{ item.name }} </span>
        <span
          v-if="isSelected(item)"
          class="item-checked"
          @click="removeItem(item)"
        >
          <a-icon type="close" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { cloneDeep } from "lodash";

export default {
  name: "SelectMultiple",
  props: {
    values: {
      type: Array,
      default: function () {
        return [];
      },
    },
    listItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      isOpened: false,
      selectedItems: cloneDeep(this.values),
      canClose: true,
    };
  },
  methods: {
    toggle: function () {
      if (this.isOpened) {
        this.close();
      } else {
        this.isOpened = true;
      }
    },
    close: function () {
      if (this.canClose) {
        this.isOpened = false;
        this.$emit("change", this.selectedItems);
      }
      this.canClose = true;
    },
    addCurrent: function (item) {
      if (this.canClose) {
        if (
          !this.selectedItems.find(
            (selectedItem) => selectedItem.key === item.key
          )
        ) {
          this.selectedItems.push(item);
        }
      }
    },
    removeItem(itemToDelete) {
      const newArr = this.selectedItems.filter(
        (item) => item.key !== itemToDelete.key
      );
      this.selectedItems = newArr;
      this.canClose = false;
    },
    isSelected(item) {
      return this.selectedItems.find(
        (selectedItem) => selectedItem.key === item.key
      );
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
.select-selected {
  background-color: #f8f8f8 !important;
}
.item-checked {
  position: absolute;
  right: 12px;
}
</style>
